export interface Team {
  name: string
  image: string
  role: string
  description?: string
  linkedIn?: string
  github?: string
}

export const team: Team[] = [
  {
    name: 'Victoria',
    role: 'Chief Executive Officer',
    linkedIn: 'https://www.linkedin.com/in/victoria-iko-ojo-ugbedeojo-7b35aa80/',
    image: '/images/profile/1.png',
    description:
      "Vicky is an experienced growth manager and crypto entrepreneur. She founded BeCryptoWiz and she grew significant crypto communities of Corestarter. She's a proficient networker with extensive contacts in the African crypto ecosystem",
  },
  {
    name: 'Ruddy',
    role: 'Chief Operating Officer',
    github: 'https://github.com/ru4871SG',
    linkedIn: 'https://www.linkedin.com/in/ruddy-setiadi-gunawan/',
    image: '/images/profile/2.png',
    description:
      'Ruddy has previously worked with various crypto projects, including CoinMarketCap, Nervos, Origin Protocol, and Publish0x. He is a data analyst with professional certification from Google. He is also actively pursuing education in data science and AI',
  },
  {
    name: 'Samuel',
    role: 'Chief Marketing Officer',
    linkedIn: 'https://www.linkedin.com/in/engrsamest/',
    image: '/images/profile/samuel.png',
    description:
      'Diversified experience in crypto and project management. Samuel is a blockchain educator with deep connections to the African crypto industry. Previously with MantraDAO, Strongnode, Orion Protocol, NANO, Darwinia, Sentinel, and others',
  },
  {
    name: 'Philip',
    role: 'Chief Technology Officer',
    github: 'https://github.com/akhils10',
    linkedIn: 'https://www.linkedin.com/in/philip-akhilome/',
    image: '/images/profile/philip.png',
    description:
      'Philip is a highly innovative polyglot software engineer and blockchain developer with over 10 years programming experience. He is a co-founder at Guild Protocol, Buttons Labs and Techmybrand. Has experiences leading distributed teams',
  },
  {
    name: 'Nano',
    role: 'Frontend Dev',
    github: 'https://github.com/nano235',
    linkedIn: 'https://www.linkedin.com/in/revival-nano-adolor/',
    image: '/images/profile/nano.png',
    description:
      'Nano is a software engineer with more than 5 years of experience in software engineering and 1+ years’ experience in blockchain development. He is also a strong engineering professional with a B.Eng focused in Electrical and Electronics Engineering from University of Benin',
  },
  {
    name: 'Henry',
    role: 'Smart Contract',
    github: 'https://github.com/Hendobox',
    linkedIn: 'https://www.linkedin.com/in/henry-onyebuchi-116501123/',
    image: '/images/profile/5.png',
    description:
      'Henry is a smart-contract developer with over two years of experience in building decentralized applications in the blockchain industry. Work experience include Rarecandy3D and DeSpace protocol. Henry has a master’s degree in Mechanical Engineering',
  },
  {
    name: 'Jay',
    role: 'BDM',
    linkedIn: 'https://www.linkedin.com/feed/',
    image: '/svgs/profile.svg',
    description:
      'Jay is a graduate of Leiden University in the Netherlands. He has ten years of experience in public relations and business development with strong connections to media, KOLs and communities in Asia',
  },
]

export const advisors: Team[] = [
  {
    name: 'Marcin',
    role: 'Operations Advisor',
    linkedIn: 'https://www.linkedin.com/in/marcinkolago/',
    image: '/images/profile/marcin.png',
    description:
      'Startup mentor and corporate innovator, leading teams, managing major projects. Worked for a corporation investing in crypto before it was fashionable. Now focused on helping teams avoid simple mistakes.',
  },
  {
    name: 'CryptoEdit',
    role: 'Research & Content Lead',
    linkedIn: 'https://www.linkedin.com/feed/',
    image: '/images/profile/9.png',
    description:
      'With experience producing engaging content, CryptoEdit has become synonymous with quality, having helped many emerging and established projects. The CryptoEdit team help tell the projects story and provide ongoing research on the rapidly changing crypto industry and landscape.',
  },
  {
    name: 'Francis',
    role: 'Strategic Advisor',
    linkedIn: 'https://www.linkedin.com/in/obasi-francis-ifegwu-79829794/',
    image: '/images/profile/7.png',
    description:
      'Obasi has over 7 years of experience in the start-up environment, he is the CEO of DeSpace Protocol. With his massive experience, Obasi will advise Deftify on strategies to build a successful DeFi and NFT brand for the entire Deftify ecosystem.',
  },
]
